import React, { useState } from "react";

import DefaultLayout from "../../../layouts/default";

const LEP = () => {
  const [akkordeonOpen, setAkkordeonOpen] = useState(false);
  const [akkordeon2Open, setAkkordeon2Open] = useState(false);
  return (
    <DefaultLayout title="WiCare|now LEP">
      <div className="white wicare">
        <div className="navContentSpacerHelper"></div>
        <header className="navWrapper">
          <nav className="mainNav">
            <div className="mainNavBackgroundHelper"></div>
            <div className="logo">
              <a href="/">
                <img
                  src="/assets/images/logos/wigasoft-logo.svg"
                  alt="WigaSoft Logo"
                  width="175"
                />
              </a>
            </div>
            <div className="mainNav-opener"></div>
            <ul className="mainLinks">
              <li>
                <a href="/news/">News</a>
              </li>
              <li>
                <a href="/dokumentationsloesungen/heime/">Heime</a>
              </li>
              <li>
                <a href="/dokumentationsloesungen/spitaeler/">Spitäler</a>
              </li>
              <li>
                <a href="/dokumentationsloesungen/rehabilitation/">
                  Rehabilitation
                </a>
              </li>
              <li>
                <a href="/portrait/">Über uns</a>
              </li>
              <li>
                <a href="/support/">Support</a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/wigasoft-ag">
                  <div className="linkedInIcon" />
                </a>
              </li>
            </ul>
          </nav>

          <nav className="subNav2">
            <div className="subNav2Product">
              <div className="productImage">
                <img
                  src="/assets/images/logos/WCN-LEP.svg"
                  alt="WiCare LEP Logo"
                  width="40"
                />
              </div>
              <div className="productText">
                <span className="line">WiCare|now LEP</span>
                <span className="line">
                  <span className="is-visible-desktop">&nbsp;für mehr</span>
                  &nbsp;Transparenz in der Pflege
                </span>
              </div>
            </div>
            <div className="subNav2-opener"></div>
            <ul className="subNav2Links">
              <li className="is-active">
                <a href="/dokumentationsloesungen/wicare-lep/">Übersicht</a>
              </li>
              <li>
                <a href="/dokumentationsloesungen/wicare-lep/technik/">
                  Technik
                </a>
              </li>
              <li>
                <a href="/dokumentationsloesungen/wicare-lep/referenzen/">
                  Referenzen
                </a>
              </li>
              <li>
                <a href="/dokumentationsloesungen/wicare-lep/downloads/">
                  Downloads
                </a>
              </li>
            </ul>
          </nav>
        </header>

        <div className="menuBackground">
          <section className="mainBanner bannerWeiss">
            <div className="wrapper has-padding-top has-padding-bottom">
              <div className="bannerFlex">
                <div>
                  <img
                    className="logo-product"
                    src="/assets/images/logos/WCN-LEP.svg"
                  />
                </div>
                <div className="bannerPadding">
                  <h1>
                    WiCare|now LEP<sup>®</sup>
                  </h1>
                  <h3 className="padding-25-bottom">
                    für mehr Transparenz in der Pflege
                  </h3>

                  <p className=" bannerText">
                    Mit WiCare|now LEP sichern Sie sich die Auswertungen nach
                    der Methode LEP sowie die praxisbezogenen
                    Spezialauswertungen der WigaSoft AG.
                  </p>
                </div>
              </div>
            </div>
          </section>

          <div className="wrapper">
            <section>
              <h3 className="smallStaticPadding-bottom h3Bold text-centered-td">
                [R]Evolutionär
              </h3>
              <p className="fliesstextLead smallStaticPadding-bottom text-centered-td">
                In knapp 30 Jahren von Papierbelegen zur Weblösung. 1993 wurden
                LEP-Daten erstmalig mit optischen Beleglesern zentral erfasst
                und ausgewertet. Seit 1997 werden mit unser Softwarelösung
                WiCare|LEP, damals v.a. noch mit Unterstützung von
                Barcode-Lesern, LEP-Leistungen dezentral auf den Stationen
                erfasst.
              </p>
              <p className="fliesstextLead text-centered-td">
                Für die LEP-zertifizierte Applikation war es Zeit für ein
                "Facelift". Mit WiCare|now LEP bieten wir eine webbasierte,
                plattformunabhängige Lösung an.
              </p>
            </section>
          </div>

          <div className="wrapper mobile-tablet-no-padding">
            <section className="cContainer is-background-color-green is-color-white rounded">
              <h4 className="smallStaticPadding-bottom text-centered-td h4Bold">
                Best Features
              </h4>
              <div className="ccHalfFlex">
                <div>
                  <ul className="featuresList">
                    <li>Webbasiert, plattformunabhängig, integrierbar</li>
                    <li>
                      Standardisierte, logische und effiziente
                      Leistungserfassung nach der Methode LEP
                    </li>
                    <li>
                      LEP Kataloge auf unterschiedlichen Stufen (Ebene Variablen
                      oder Interventionen)
                    </li>
                    <li>Unterstützt verschiedene Berufsgruppen</li>
                    <li>Bietet ein breites Set an Auswertungen</li>
                    <li>Unterstützt den LEP-Datenvergleich</li>
                  </ul>
                </div>
                <div>
                  <ul className="featuresList">
                    <li>Export der Rohdaten von pflegerischen Kennzahlen</li>
                    <li>CHOP99.C kann automatisch abgeleitet werden</li>
                    <li>
                      Zusammenspiel mit{" "}
                      <a
                        className="is-color-white"
                        href="/dokumentationsloesungen/wicare-le/"
                      >
                        WiCare|now LE
                      </a>
                    </li>
                    <li>
                      Leistungsdaten aus der elektronischen Pflegedokumentation
                      WiCare|Doc oder anderen Klinikinformationssystemen können
                      übernommen werden
                    </li>
                    <li>
                      Schnittstellen zu ERP-Systemen und zur
                      Personaleinsatzplanung erfolgreich im Einsatz
                    </li>
                  </ul>
                </div>
              </div>
            </section>
          </div>

          <div className="wrapper wrapper-xl">
            <section className="margin-45-bottom">
              <div
                className={
                  akkordeonOpen
                    ? "funktionenToggle is-background-color-green selected"
                    : "funktionenToggle is-background-color-green"
                }
                onClick={() => setAkkordeonOpen(!akkordeonOpen)}
              >
                <h3>Funktionen</h3>
                <img src="/assets/images/gestaltungselemente/akkordeon-arrow.svg" />
              </div>
              <div>
                <article className="mobilUnterwegs">
                  <div className="imgDiv text-centered">
                    <a
                      href="https://wigasoft-prod.imgix.net/screenshots/lep/2021/1_Praesenzzeit.png?auto=format,compress"
                      data-lightbox="lep-1"
                      data-actual-title="Präsenzzeit"
                    >
                      <img src="https://wigasoft-prod.imgix.net/screenshots/lep/2021/1_Praesenzzeit.png?auto=format,compress" />
                    </a>
                  </div>
                  <div className="textDiv text-centered-m">
                    <h4 className="h4Bold">Präsenzzeit</h4>
                    <p className="fliesstext">
                      Die tägliche Präsenzzeit wird via Schnittstelle aus der
                      Planung übernommen oder manuell erfasst.
                    </p>
                    <div className="devices">
                      <img
                        src="/assets/images/icons/Icon_Desktop.jpg"
                        title="Desktop"
                      />
                      <img
                        src="/assets/images/icons/Icon_Tablet.jpg"
                        title="Tablet"
                      />
                    </div>
                  </div>
                </article>
                <div className="dottedBorder"></div>
                <article className="mobilUnterwegs">
                  <div className="imgDiv text-centered">
                    <a
                      href="https://wigasoft-prod.imgix.net/screenshots/lep/2021/2_Erfassung.png?auto=format,compress"
                      data-lightbox="lep-1"
                      data-actual-title="Erfassung"
                    >
                      <img src="https://wigasoft-prod.imgix.net/screenshots/lep/2021/2_Erfassung.png?auto=format,compress" />
                    </a>
                  </div>
                  <div className="textDiv text-centered-m">
                    <h4 className="h4Bold">Erfassung</h4>
                    <p className="fliesstext">
                      Alle erbrachten Patienten-Leistungen werden detailliert
                      pro Schicht erfasst. Erbrachte Gruppen-Leistungen lassen
                      sich leicht und effizient im Ganzen oder anteilsmässig
                      erfassen. Fallunabhängige Leistungen können ebenfalls
                      erfasst werden.
                    </p>
                    <div className="devices">
                      <img
                        src="/assets/images/icons/Icon_Desktop.jpg"
                        title="Desktop"
                      />
                      <img
                        src="/assets/images/icons/Icon_Tablet.jpg"
                        title="Tablet"
                      />
                    </div>
                  </div>
                </article>
                <div className="dottedBorder"></div>
                <article className="mobilUnterwegs">
                  <div className="imgDiv text-centered">
                    <a
                      href="https://wigasoft-prod.imgix.net/screenshots/lep/2021/3_subjektive_Einschaetzung.png?auto=format,compress"
                      data-lightbox="lep-1"
                      data-actual-title="Subjektive Einschätzung"
                    >
                      <img src="https://wigasoft-prod.imgix.net/screenshots/lep/2021/3_subjektive_Einschaetzung.png?auto=format,compress" />
                    </a>
                  </div>
                  <div className="textDiv text-centered-m">
                    <h4 className="h4Bold">Subjektive Einschätzung</h4>
                    <p className="fliesstext">
                      Pro Schicht kann eine subjektive, stationsbezogene
                      Einschätzung und ein Kommentar erfasst werden.
                    </p>
                    <div className="devices">
                      <img
                        src="/assets/images/icons/Icon_Desktop.jpg"
                        title="Desktop"
                      />
                      <img
                        src="/assets/images/icons/Icon_Tablet.jpg"
                        title="Tablet"
                      />
                    </div>
                  </div>
                </article>
                <div className="dottedBorder"></div>
                <article className="mobilUnterwegs">
                  <div className="imgDiv text-centered">
                    <a
                      href="https://wigasoft-prod.imgix.net/screenshots/lep/2021/4_Validierung.png?auto=format,compress"
                      data-lightbox="lep-1"
                      data-actual-title="Validierung"
                    >
                      <img src="https://wigasoft-prod.imgix.net/screenshots/lep/2021/4_Validierung.png?auto=format,compress" />
                    </a>
                  </div>
                  <div className="textDiv text-centered-m">
                    <h4 className="h4Bold">Validierung</h4>
                    <p className="fliesstext">
                      Die Station gibt nach der Kontrolle der Daten den Tag
                      frei.
                    </p>
                    <div className="devices">
                      <img
                        src="/assets/images/icons/Icon_Desktop.jpg"
                        title="Desktop"
                      />
                      <img
                        src="/assets/images/icons/Icon_Tablet.jpg"
                        title="Tablet"
                      />
                    </div>
                  </div>
                </article>

                <div className="dottedBorder"></div>

                <article className="mobilUnterwegs">
                  <div className="imgDiv text-centered">
                    <a
                      href="https://wigasoft-prod.imgix.net/screenshots/lep/2021/6_Bettensperrung.png?auto=format,compress"
                      data-lightbox="lep-1"
                      data-actual-title="Bettensperrung"
                    >
                      <img src="https://wigasoft-prod.imgix.net/screenshots/lep/2021/6_Bettensperrung.png?auto=format,compress" />
                    </a>
                  </div>
                  <div className="textDiv text-centered-m">
                    <h4 className="h4Bold">Bettensperrung</h4>
                    <p className="fliesstext">
                      Mit der Komponente «Bettensperrung» ist es möglich,
                      gesperrte Betten zuhanden der Disposition stationsbezogen
                      zu erfassen.
                    </p>
                    <div className="devices">
                      <img
                        src="/assets/images/icons/Icon_Desktop.jpg"
                        title="Desktop"
                      />
                      <img
                        src="/assets/images/icons/Icon_Tablet.jpg"
                        title="Tablet"
                      />
                    </div>
                  </div>
                </article>
                <div className="dottedBorder"></div>
                <article className="mobilUnterwegs">
                  <div className="imgDiv text-centered">
                    <a
                      href="https://wigasoft-prod.imgix.net/screenshots/lep/2021/5_Auswertungen.png?auto=format,compress"
                      data-lightbox="lep-1"
                      data-actual-title="Auswertungen"
                    >
                      <img src="https://wigasoft-prod.imgix.net/screenshots/lep/2021/5_Auswertungen.png?auto=format,compress" />
                    </a>
                  </div>
                  <div className="textDiv text-centered-m">
                    <h4 className="h4Bold">Auswertungen</h4>
                    <p className="fliesstext">
                      Mit WiCare|now LEP werden dem Anwenderbetrieb eine
                      Vielfalt von Auswertungen und Statistiken bereitgestellt.
                      Die Daten können aus Sicht der Station oder aus Sicht der
                      Patienten ausgewertet werden.
                      <br />
                      <br />
                      Die Auswertungen können für die Weiterverarbeitung ins
                      Excel exportiert werden. Wiederkehrende Auswertungen
                      können mittels «Batch-Verarbeitung» automatisiert an
                      Anwendergruppen per Mail zugestellt werden.
                    </p>
                    <div className="devices">
                      <img
                        src="/assets/images/icons/Icon_Desktop.jpg"
                        title="Desktop"
                      />
                      <img
                        src="/assets/images/icons/Icon_Tablet.jpg"
                        title="Tablet"
                      />
                    </div>
                  </div>
                </article>
              </div>
            </section>
          </div>
        </div>

        <section className="nutzen">
          <div
            className={
              akkordeon2Open
                ? "funktionenToggle is-background-color-green selected"
                : "funktionenToggle is-background-color-green"
            }
            onClick={() => setAkkordeon2Open(!akkordeon2Open)}
          >
            <h3>Nutzen</h3>
            <img src="/assets/images/gestaltungselemente/akkordeon-arrow.svg" />
          </div>
          <div>
            <div className="wrapper">
              <div className="selection has-padding-top">
                <div className="tab-select selected" id="select1">
                  <h4>User</h4>
                </div>
                <div className="tab-select" id="select2">
                  <h4>Management</h4>
                </div>
                <div className="tab-select" id="select3">
                  <h4>IT</h4>
                </div>
              </div>
            </div>
            <div className="content is-background-color-grey">
              <div className="wrapper">
                <div
                  id="content1"
                  className="selected has-padding-top has-padding-bottom"
                >
                  <div>
                    <h4 className="h4Bold">Übersichtlich und klar</h4>
                    <p className="fliesstext7">
                      Die Mitarbeiter können die täglich erbrachten Leistungen
                      übersichtlich, klar und effizient erfassen.
                    </p>
                  </div>
                  <div>
                    <ul className="featuresList">
                      <li>
                        Methodenkonforme Leistungserfassung über die Navigation
                        in den Belegen, einzelne Variablen, oder als Gruppe
                      </li>
                      <li>
                        individuelle Leistungs-Blöcke/-Pakete sind
                        konfigurierbar, Standard- und Auswahlleistungen können
                        vom Anwender verändert werden
                      </li>
                      <li>die Leistungserfassung erfolgt Schichtorientiert</li>
                      <li>für mobile Geräte (Tablets) optimiert</li>
                      <li>
                        Übernahme der dokumentierten Handlungen aus WiCare|now
                        Doc und anderen Klinikinformationssystemen
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  id="content2"
                  className="has-padding-top has-padding-bottom"
                >
                  <div>
                    <h4 className="h4Bold">Integrierbar</h4>
                    <p className="fliesstextLead">
                      WiCare|now LEP liefert die Grundlagen für Analysen,
                      Prognosen und Vergleiche. Die Daten werden übersichtlich
                      aufbereitet und die Pflegetätigkeiten transparent
                      dargestellt.
                      <br />
                      <br />
                      Führungskennzahlen ermöglichen dem Pflegemanagement die
                      Prozesse zu steuern. WiCare|now LEP dient dafür als
                      Steuerungsinstrument – so erhalten Führungspersonen den
                      Überblick und erlangen Kosten- und Leistungstransparenz
                      gegenüber Dritten.
                      <br />
                      <br />
                      Nach den wichtigsten Kenndaten wird die
                      Stellenbewirtschaftung ausgerichtet, Arbeitspläne erstellt
                      und die Zuteilung der Patienten vorgenommen.
                    </p>
                  </div>
                  <div>
                    <ul className="featuresList">
                      <li>
                        Webbasierend, Geräte- und Betriebssystemunabhängig
                      </li>
                      <li>
                        Lösung auf dem aktuellsten Stand der Software-Technik
                      </li>
                      <li>Liefert Kennzahlen für den Benchmark</li>
                      <li>Leistungsvalidierung und Plausibilitätsprüfungen</li>
                      <li>Minimaler Erfassungsaufwand</li>
                      <li>
                        Eigene Auswertungen können innerhalb WiCare|now LEP
                        bereitgestellt werden
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  id="content3"
                  className="has-padding-top has-padding-bottom"
                >
                  <div>
                    <h4 className="h4Bold">Standardisiert und normiert</h4>
                    <p className="fliesstextLead">
                      WiCare|now LEP passt sich in die vorgegebene IT-Landschaft
                      ein. Die Orientierung an gängigen Standards wie z.B. ALIS
                      und HL7 hilft bei der einfachen Integration von
                      Dritt-Systemen. Die Kompatibilität mit den neusten Windows
                      Server- und Client-Betriebssystemen sowie den aktuellen
                      Versionen von Microsoft SQL Server und Datenbanksysteme
                      erlauben den Betrieb stabiler und ausbaufähiger Systeme,
                      die sich nahtlos in Standard-Netzwerke einbinden lassen.
                    </p>
                  </div>
                  <div>
                    <ul className="featuresList">
                      <li>
                        Webbasierend, Geräte- und Betriebssystemunabhängig
                      </li>
                      <li>für mobile Geräte (Tablets) optimiert</li>
                      <li>
                        Aufruf aus oder Integration in Drittapplikationen via
                        URL möglich
                      </li>
                      <li>ActiveDirectory und SingleSignOn unterstützt</li>
                      <li>
                        verfügt mit WiCare|now Admin über ein einfach zu
                        bedienendes Konfigurationsmodul
                      </li>
                      <li>
                        stellt ein flexibel konfigurierbares Schnittstellenmodul
                        bereit
                      </li>
                      <li>
                        basiert auf einer strukturiert aufgebauten Datenbank mit
                        einfach lesbarem Datenmodell
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="wrapper">
            <div className="bContainer green margin-45-bottom">
              <h5>Integrierbar</h5>
              <p>
                Die bereitgestellten WiCare|now LEP Komponenten können als
                Webfacette einfach in bestehende Dokumentationssysteme
                integriert werden.
              </p>
            </div>
            <div className="has-margin-bottom">
              <p className="fliesstextS is-color-grey text-centered margin-0-auto">
                LEP<sup>®</sup> ist ein eingetragenes Warenzeichen der Firma LEP
                AG. Alle weiteren Waren- und Markenzeichen unterliegen
                uneingeschränkt den Bestimmungen gültiger Kennzeichenrechte
                sowie den Bestimmungen der jeweiligen Eigentümer.
              </p>
            </div>
          </div>
        </section>

            <section className="newsletterAnmeldung">
      <div className="wrapper nlPadding-top">
        <a className="btn grey" href="/newsletter">Newsletteranmeldung</a><br /><br /><br />
      </div>
    </section>

        <footer className="mainFooter">
          <div className="wrapper">
            <div className="breadcrumbs has-padding-small-top smallStaticPadding-bottom">
              <ul>
                <li>
                  <a href="/">Startseite</a>
                </li>
                <li>
                  <a href="/dokumentationsloesungen/wicare-lep/">WiCare|LEP</a>
                </li>
              </ul>
            </div>
            <div className="footerNotAnotherFlexboxButAGrid linklist">
              <div className="flex-item1">
                <ul>
                  <li>
                    <a href="/dokumentationsloesungen/heime/">
                      <strong>Heime</strong>
                    </a>
                  </li>
                  <li>
                    <a href="/dokumentationsloesungen/spitaeler/">
                      <strong>Spitäler</strong>
                    </a>
                  </li>
                  <li>
                    <a href="/dokumentationsloesungen/rehabilitation/">
                      <strong>Rehabilitation</strong>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="flex-item2">
                <ul>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-doc-l/">
                      <span className="is-visible-desktop">WiCare|Doc</span>
                      <span className="is-visible-tablet-until-PC">WCD</span>-L
                      BESA
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-doc-l/index_rai">
                      <span className="is-visible-desktop">WiCare|Doc</span>
                      <span className="is-visible-tablet-until-PC">WCD</span>-L
                      RAI-NH
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-doc-b/">
                      <span className="is-visible-desktop">WiCare|Doc</span>
                      <span className="is-visible-tablet-until-PC">WCD</span>-B
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-doc-h/">
                      <span className="is-visible-desktop">WiCare|Doc</span>
                      <span className="is-visible-tablet-until-PC">WCD</span>-H
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-doc-r/">
                      <span className="is-visible-desktop">WiCare|Doc</span>
                      <span className="is-visible-tablet-until-PC">WCD</span>-R
                    </a>
                  </li>
                </ul>
              </div>
              <div className="flex-item3">
                <ul>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-tacs/">
                      WiCare|now tacs<sup>&#174;</sup>
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-lep/">
                      WiCare|now LEP<sup>&#174;</sup>
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-le/">
                      WiCare|now LE
                    </a>
                  </li>
                </ul>
              </div>
              <div className="flex-item4">
                <ul>
                  <li>
                    <a href="/portrait/">
                      <strong>Über uns</strong>
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/portrait/lehrlinge/">Lernende</a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/portrait/partner/">Partner</a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/portrait/mitgliedschaften/">Mitgliedschaften</a>
                  </li>
                  <li>
                    <a href="/portrait/kontakt/">Kontakt und Anfahrt</a>
                  </li>
                </ul>
              </div>
              <div className="flex-item5">
                <ul>
                  <li>
                    <a href="/support/">
                      <strong>Support</strong>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row copyright">
            <div className="wrapper">
              <div className="column">
                © 2025 WigaSoft. Alle Rechte vorbehalten.&nbsp;
              </div>
              <div className="column has-padding-left">
                <ul>
                  <li>
                    <a href="/datenschutz/">Datenschutzerklärung</a>
                  </li>
              <li>
                <a href="/impressum/">Impressum</a>
              </li>
                </ul>
              </div>
              <div className="column align-right">
                <a href="/portrait/kontakt/" className="anfahrt">
                  <img
                    id="location-pointer"
                    src="/assets/images/icons/icon-anfahrt.svg"
                  />
                </a>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </DefaultLayout>
  );
};

export default LEP;
